// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-nous-contacter-tsx": () => import("./../../../src/pages/nous-contacter.tsx" /* webpackChunkName: "component---src-pages-nous-contacter-tsx" */),
  "component---src-pages-quizz-index-tsx": () => import("./../../../src/pages/quizz/index.tsx" /* webpackChunkName: "component---src-pages-quizz-index-tsx" */),
  "component---src-pages-quizz-syllabes-tsx": () => import("./../../../src/pages/quizz/syllabes.tsx" /* webpackChunkName: "component---src-pages-quizz-syllabes-tsx" */),
  "component---src-pages-recueils-auteurs-tsx": () => import("./../../../src/pages/recueils/auteurs.tsx" /* webpackChunkName: "component---src-pages-recueils-auteurs-tsx" */),
  "component---src-pages-recueils-index-tsx": () => import("./../../../src/pages/recueils/index.tsx" /* webpackChunkName: "component---src-pages-recueils-index-tsx" */),
  "component---src-pages-recueils-thematiques-tsx": () => import("./../../../src/pages/recueils/thematiques.tsx" /* webpackChunkName: "component---src-pages-recueils-thematiques-tsx" */),
  "component---src-pages-repertoire-auteurs-tsx": () => import("./../../../src/pages/repertoire/auteurs.tsx" /* webpackChunkName: "component---src-pages-repertoire-auteurs-tsx" */),
  "component---src-pages-repertoire-index-tsx": () => import("./../../../src/pages/repertoire/index.tsx" /* webpackChunkName: "component---src-pages-repertoire-index-tsx" */),
  "component---src-templates-anthology-article-tsx": () => import("./../../../src/templates/anthology-article.tsx" /* webpackChunkName: "component---src-templates-anthology-article-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-biography-tsx": () => import("./../../../src/templates/biography.tsx" /* webpackChunkName: "component---src-templates-biography-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-recueils-auteurs-template-tsx": () => import("./../../../src/templates/recueils/auteurs/template.tsx" /* webpackChunkName: "component---src-templates-recueils-auteurs-template-tsx" */),
  "component---src-templates-recueils-thematiques-template-tsx": () => import("./../../../src/templates/recueils/thematiques/template.tsx" /* webpackChunkName: "component---src-templates-recueils-thematiques-template-tsx" */),
  "component---src-templates-repertoire-auteurs-template-tsx": () => import("./../../../src/templates/repertoire/auteurs/template.tsx" /* webpackChunkName: "component---src-templates-repertoire-auteurs-template-tsx" */)
}

