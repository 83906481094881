module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Temple du Haïku","short_name":"Temple du Haïku","start_url":"/?utm_source=web_app_manifest","theme_color":"#f4f5f7","background_color":"#f4f5f7","display":"standalone","icons":[{"src":"/manifest/16x16.png","sizes":"16x16","type":"image/png"},{"src":"/manifest/64x64.png","sizes":"64x64","type":"image/png"},{"src":"/manifest/32x32.png","sizes":"32x32","type":"image/png"},{"src":"/manifest/24x24.png","sizes":"24x24","type":"image/png"},{"src":"/manifest/192x192.png","type":"image/png","sizes":"192x192"},{"src":"/manifest/512x512.png","type":"image/png","sizes":"512x512"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.temple-du-haiku.fr"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"showCaptions":["alt","title"]}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/www.temple-du-haiku.fr/www.temple-du-haiku.fr"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"temple-du-haiku.fr"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
